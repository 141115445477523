export const EXPERIENCE = [
  {
    role: 'Front-End Engineer',
    name: 'CM Group',
    link: 'https://cmgroup.com/',
    duration: 'March 2022 - Present',
    description:
      'At <a href="https://cmgroup.com/" rel="noreferrer" target="_blank">CM Group</a>, my responsibilities include:',
    list: [
      'Developing and contributing to design system tools that help teams build UIs quickly and efficiently.',
      'Working on tools to optimise feedback loops between product, design, and engineering teams.',
      'Collaborating with designers at various stages of the design and development process to build and improve UI/X.',
      'Working closely with other engineers to develop solutions and help educate peers on best practices for UI development.',
      'Ensuring UIs are built in the most accessible way, and drive the direction of accessibility with design and engineering teams.',
      'Creating and regularly updating documentation for UI patterns and components so that product, design, and engineering teams can benefit from a centralised source of information.',
      'Helping drive front-end engineering best practices, build and extend front-end infrastructure and libraries.',
    ],
  },
  {
    role: 'Full Stack Developer',
    name: 'Livn',
    link: 'https://livn.world/',
    duration: 'January 2017 - February 2022',
    description:
      'At <a href="https://livn.world" rel="noreferrer" target="_blank">Livn</a>, I focused on building and maintaining various web apps with a small JavaScript team. This role has given me the opportunity to design and develop these projects with a great amount of freedom from the very beginning, allowing me to learn a great deal about managing projects and other developers. My creations include:',
    list: [
      'a <a href="https://karibu.livngds.com/go" rel="noreferrer" target="_blank">store management and sales platform</a> to assist our clients;',
      'an <a href="https://gitlab.com/livnDevelopers/rahisi" rel="noreferrer" target="_blank">example implementation of Livn\'s API</a> with thorough documentation;',
      "an entire backend dashboard to manage all of Livn's data and clients;",
      "a web app which serves as a mock implementation of Livn's API to allow our backend developers to test their work.",
    ],
  },
]
