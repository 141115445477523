export const PROJECTS = [
  {
    id: 'aid',
    name: 'AI Dreams',
    url: 'https://aidreams.world/',
    description:
      'A simple blog created with React and Next. It uses TailwindCSS to assist with styling and GraphCMS to organise data.',
    images: [
      'https://res.cloudinary.com/starborn/image/upload/v1666166845/sha-codes/projects/ai-dreams-01_xirymy.jpg',
      'https://res.cloudinary.com/starborn/image/upload/v1666166846/sha-codes/projects/ai-dreams-02_ftyi7u.jpg',
      'https://res.cloudinary.com/starborn/image/upload/v1666166847/sha-codes/projects/ai-dreams-03_gyr1wt.jpg',
    ],
    roles: ['UI Design', 'Development'],
    tech: ['React', 'Next', 'GraphQL'],
  },
  {
    id: 'og',
    name: 'Outrun Games',
    url: 'https://outrun.games/',
    description:
      'A website dedicated to the analysis of games created with React and drawing its data from MongoDB. It uses TailwindCSS to assist with styling, react-router for navigation, and framer-motion for its transitions.',
    images: [
      'https://res.cloudinary.com/starborn/image/upload/v1666194703/sha-codes/projects/outrun-games-02_pboj8p.jpg',
      'https://res.cloudinary.com/starborn/image/upload/v1666194703/sha-codes/projects/outrun-games-01_ad5txk.jpg',
      'https://res.cloudinary.com/starborn/image/upload/v1666194703/sha-codes/projects/outrun-games-03_cqnvun.jpg',
    ],
    roles: ['UI Design', 'Database Design', 'Development'],
    tech: ['React', 'MongoDB'],
  },
  {
    id: 'mm',
    name: 'myMoney',
    description:
      'An app for simply tracking income and expenses aiming to be as simple as possible, without any automation or connections to other servies. It was created with React Native, connects to a Node+Express server, and draws its data from MongoDB. It uses Native Base to assist with styling. Development is ongoing.',
    images: [
      'https://res.cloudinary.com/shianra/image/upload/v1639498096/sha/projects/lucid-01-min_ghnbie.jpg',
    ],
    roles: ['UI Design', 'Database Design', 'Development'],
    tech: ['React Native', 'Redux', 'Node', 'Express', 'MongoDB'],
  },
  {
    id: 'vcfoa',
    name: 'VCFO Accountants',
    url: 'https://vcfoaccountants.com.au/',
    description:
      'An accounting website created with React and Next, using GraphCMS to handle its blog section.',
    images: [
      'https://res.cloudinary.com/starborn/image/upload/v1666194703/sha-codes/projects/vcfo-accountants-02_kwubxb.jpg',
      'https://res.cloudinary.com/starborn/image/upload/v1666194702/sha-codes/projects/vcfo-accountants-01_ch4udq.jpg',
      'https://res.cloudinary.com/starborn/image/upload/v1666194703/sha-codes/projects/vcfo-accountants-03_zmp1fw.jpg',
    ],
    roles: ['UI Design', 'Development'],
    tech: ['React', 'Next', 'GraphQL'],
  },
  {
    id: 'gr',
    name: 'Genshin Recipes',
    url: 'https://genshin.recipes/',
    description:
      'A website for organising game recipes transposed into real life created with React and drawing its data from MongoDB. It uses ChakraUI to assist with styling, react-router for navigation, and framer-motion for its transitions.',
    images: [
      'https://res.cloudinary.com/starborn/image/upload/v1666195078/sha-codes/projects/genshin-recipes-01_h6mqhb.jpg',
      'https://res.cloudinary.com/starborn/image/upload/v1666195078/sha-codes/projects/genshin-recipes-02_zt4fz4.jpg',
      'https://res.cloudinary.com/starborn/image/upload/v1666195078/sha-codes/projects/genshin-recipes-03_qpsvx8.jpg',
    ],
    roles: ['UI Design', 'Database Design', 'Development'],
    tech: ['React', 'MongoDB'],
  },
  {
    id: 'dop',
    name: 'Dr Olga Palma',
    url: 'https://drolgap.com.au/',
    description:
      'A website for a dental practice created with React. It uses TailwindCSS to assist with styling and react-router for navigation.',
    images: [
      'https://res.cloudinary.com/shianra/image/upload/v1639498096/sha/projects/dr-olga-01-min_yew5uu.jpg',
    ],
    roles: ['UI Design', 'Development'],
    tech: ['React'],
  },
]
